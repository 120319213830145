@import '../base/variables';
@import '../base/animations';

.links {
  margin-bottom: 20rem;
}

.links__header {
  margin-bottom: 1.6rem;
}

.links__link {
  text-transform: uppercase;
  font-size: 4rem;
  font-weight: 700;
  line-height: 1.167;
  letter-spacing: 0;
  transition: all 300ms ease;

  @supports (-webkit-text-stroke: .08rem $black) {
    -webkit-text-stroke-color: $black;
    -webkit-text-stroke-width: .08rem;
    // For if project has a color (gradient)
    background-image: linear-gradient(180deg, $white 0%, $white 50%, $black 50%, $black 100% );
    background-size: 100% 200%;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  @supports not (-webkit-text-stroke: .1rem $black) {
    // Fallback if stroke is not supported. Background color has to be set to none.
    background-color: none;
  }

  &:hover {
    // background-size: 100% 100%;
    background-position: 100% 100%;
  }
}