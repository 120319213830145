body {
  font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Raleway';
}

.roboto {
  font-family: 'roboto';
}

.raleway {
  font-family: 'Raleway';
}

.h2 {
  font-size: 1.6rem;
  font-weight: 300;
  line-height: 1.1;
  letter-spacing: -.03rem;
}