@import '../base/variables';
@import '../base/mixins';

.showcase {
  position: relative;
  margin-bottom: 1rem;
  width: 100%;
}

.showcase-wrapper {
  position: relative;
}

.showcase__image {
  overflow: hidden;
  border-radius: 1.2rem;
  box-shadow: .2rem .2rem 8rem rgba($black, 0.4);
}

.showcase__side-images {
  position: absolute;
  top: 42%;
  left: 0;
  width: 100%;
  height: 60rem;

  // @include min(xs) {
  //   top: 41%;
  // }

  @include min(s) {
    top: 43%;
    height: 55rem;
  }

  @include min(m) {
    height: 60rem;
  }
}

.showcase__side-image {
  position: absolute;
  overflow: hidden;
  border-radius: .5rem;
  box-shadow: .2rem .2rem 4rem rgba($black, 0.4);

  @include min(s) {
    border-radius: .3rem;
  }

  @include max(s) {
    &:nth-child(n + 4) {
      display: none;
    }
  }

  &--one {
    top: -5%;
    left: 0;
    width: 20rem;

    @include min(xs) {
      top: -15%;
      width: 54%;
    }
    
    @include min(s-) {
      top: -20%;
    }

    @include min(s) {
      top: 0;
      left: 0;
      width: 36%;
    }
  }

  &--two {
    top: 21%;
    left: 2%;
    width: 18rem;

    @include min(xs) {
      top: 20%;
      width: 50%;
    }

    @include min(s) {
      top: -20%;
      left: initial;
      right: 2%;
      width: 37%;
    }
  }

  &--three {
    top: 45%;
    left: 1%;
    width: 19rem;

    @include min(xs) {
      top: 53%;
      width: 52%;
    }

    @include min(s-) {
      top: 58%;
    }
    
    @include min(s) {
      top: 50%;
      left: 5%;
      width: 33%;
    }
  }

  // >= 4th will be invisible on mobile (< 720px)
  &--four {
    top: 45%;
    right: 4%;
    width: 30%;
  }
}