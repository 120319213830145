@import '../base/animations';
@import '../base/mixins';
@import '../base/variables';

.work {
  position: relative;
  margin-top: 10rem;
  margin-bottom: 10rem;
  animation: opacity 1s ease-in-out;

  @include min(s) {
    margin-top: -15rem;
  }

  @include min(m) {
    margin-top: -30rem;
  }
}

.header {
  margin-bottom: 1.6rem;
}

.work-wrapper {
  &:not(:last-of-type) {
    margin-bottom: 8rem;
  }
}

.work__item {
  &:not(:last-of-type) {
    margin-bottom: 4rem;
  }
}

.work__item-name {
  display: inline-block;
  margin-bottom: .8rem;
  color: $black;
  text-transform: uppercase;
  font-size: 3.4rem;
  font-weight: 700;
  line-height: 1.167;
  letter-spacing: 0;
  
  @supports (-webkit-text-stroke: .08rem $black) {
    -webkit-text-stroke-color: $black;
    -webkit-text-stroke-width: .08rem;
    // For if project has a color (gradient)
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  @supports not (-webkit-text-stroke: .1rem $black) {
    // Fallback if stroke is not supported. Background color has to be set to none.
    // Otherwise set color from inline css will overwrite it.
    background-color: none !important;
  }

  @include min(s) {
    font-size: 4.8rem;

    @supports (-webkit-text-stroke: .13rem $black) {
      -webkit-text-stroke-width: .13rem;
      color: $black;
    }
  }

  @include min(m) {
    font-size: 5.6rem;
    letter-spacing: -0.08rem;
  }
}

.work__suffix {
  color: $black;
  font-size: 1.2rem;
  font-weight: 500;
  margin-left: .2rem;

  @include min(s) {
    font-weight: 600;
  }
}

.work__stack {
  margin-bottom: 1.6rem;
  font-size: 1.4rem;
  font-style: italic;

  @include min(s) {
    font-size: 1.5rem;
  }

  @include min(m) {
    font-size: 1.6rem;
  }
}

.work__stack-item {
  display: inline;
}

.work__link {
  display: flex;
  font-size: 1.2rem;
  font-weight: 600;

  @include min(s) {
    font-size: 1.3rem;
  }

  @include min(m) {
    font-size: 1.4rem;
  }

  a {
    display: inline;
    color: $black;
    background-image: linear-gradient(90deg, $grey 20%, $grey-light 40%, $grey-light 60%, $grey 80%);
    background-clip: text;
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
    background-size: 200% auto;

    &:hover {
      animation: shine 1.2s linear infinite;
    }
  }
}

.work__link-icon {
  font-size: 1.1rem;
  padding-left: .8rem;
}