.button {
  padding: 1rem 2rem;
  border-radius: 10rem;
  font-weight: 400;
  font-size: 1.4rem;
  cursor: pointer;
  transition: all 100ms ease;

  &:hover {
    transform: translateY(.15rem);
  }
}

.button--primary {
  color: $white;
  background-color: $black;

  &:hover {
    background-color: $black-hover;
  }
}

.button--secondary {
  color: $black;
  box-shadow: inset 0 0 0 1px $black;
  -webkit-box-shadow: inset 0 0 0 1px $black;

  &:hover {
    background-color: $white-hover;
  }
}