// @import '../base/variables';

.contact {
  margin-bottom: 8rem;
}

.contact__header {
  margin-bottom: 1.6rem;
}

.contact__email {
  margin-bottom: 1.6rem;
  font-size: 1.8rem;
}

.contact__button-wrapper {
  display: flex;
  flex-wrap: wrap;
  row-gap: .8rem;
  column-gap: .8rem;
}