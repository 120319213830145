// Simple opacity animation
@keyframes opacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

// Reveal animation for header reveal text.
@keyframes reveal {
  0% {
    transform: translate(0,100%);
    opacity: 0;
  }
  20% {
    transform: translate(0,100%);
    opacity: 0;
  }
  100% {
    opacity: 1;
    transform: translate(0,0);
  }
}

// Visit website shine
@keyframes shine {
  to {
    background-position: -200% center;
  }
}