/* ==========================================================================
   Variables
   ========================================================================== */
@use 'sass:math';

/* Breakpoints variables
========================================================================== */

$breakpoints: (
  "xs": 400px,
  "s-": 520px,
  "s": 720px,
  "m": 1024px,
  "l": 1120px
);

/* Grid variables
========================================================================== */

$gutter:                  3.2rem !default;
$grid-columns:            12;
$grid-gap:                $gutter;
$grid-gap--mobile:        math.div(3.2rem, 2);

$max-container-width:     1120px !default;

$spacing:                 math.div($gutter, 2) !default;

/* Color variables
========================================================================== */
$black: #000000;
$white: #ffffff;          

$grey: #5F5F5F;
$grey-light: #D9D9D9;

$black-hover: #1f1f1f;
$white-hover: #fbfbfb;