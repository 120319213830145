@import '../base/variables';
@import '../base/mixins';
@import '../base/animations';

.intro {
  font-size: 1.8rem;
  margin-bottom: 15rem;
  animation: opacity 1s ease-in-out;

  @include min(s) {
    margin-bottom: 30rem;
    font-size: 3.4rem;
  }

  @include min(m) {
    font-size: 4rem;
  }

  p + p {
    margin-top: 4rem;
  }
}