@import '../base/variables.scss';
@import '../base/mixins.scss';
@import '../base/animations';

.header {
  margin-top: 9.6rem;
  margin-bottom: 15rem;

  @include min(s) {
    margin-bottom: 30rem;
  }

  @include min(m) {
    margin-top: 12.8rem;
  }
}

.header-container {
  position: relative;
}

.header__title {
  margin-bottom: 1.2rem;
  font-size: 4.8rem;
  font-weight: 800;
  line-height: 1.1;
  letter-spacing: 0;
  animation: 1s opacity ease-in-out;

  @supports (-webkit-text-stroke: .08rem $black) {
    -webkit-text-stroke-width: .08rem;
    color: $black;
  }

  @include min(xs) {
    font-size: 10vw;
    letter-spacing: -.2rem;
  }

  @include min(s) {
    margin-bottom: 1.6rem;
    font-size: 6.4rem;

    @supports (-webkit-text-stroke-width: .12rem) {
      -webkit-text-stroke-width: .12rem;
    }
  }

  @include min(m) {
    margin-bottom: 2.4rem;
    font-size: 9.6rem;
    letter-spacing: -.3rem;

    @supports (-webkit-text-stroke-width: .2rem) {
      -webkit-text-stroke-width: .2rem;
    }
  }
}

.header__start {
  position: absolute;
  top: -1.2rem;
  left: .5%;
  font-size: 1.1rem;
  font-weight: 400;
  line-height: 1.167;
  letter-spacing: -.03rem;
  animation: 1s opacity ease-in-out;

  @include min(xs) {
    top: -.8rem;
  }

  @include min(s) {
    font-size: 1.2rem;
  }

  @include min(m) {
    top: -.5rem;
    font-size: 1.5rem;
    font-weight: 500;
  }
}

.header__outline {
  display: block;
  color: $black;

  @supports (-webkit-text-stroke: .08rem $black) {
    -webkit-text-stroke-color: $black;
    -webkit-text-stroke-width: .08rem;
    color: $white;
  }

  @include min(s) {
    @supports (-webkit-text-stroke-width: .12rem) {
      -webkit-text-stroke-width: .12rem;
    }
  }

  @include min(m) {
    @supports (-webkit-text-stroke-width: .2rem) {
      -webkit-text-stroke-width: .2rem;
    }
  }
}

.header__paragraph-wrapper {
  overflow: hidden;
}

.header__paragraph {
  font-size: 1.6rem;
  font-weight: 500;
  animation: reveal 1.5s cubic-bezier(0.77, 0, 0.175, 1);

  @include min(xs) {
    font-size: 4vw;
  }

  @include min(s) {
    font-size: 2.4rem;
  }

  @include min(m) {
    font-size: 4rem;
  }
}